
























import { Component, Prop, Vue } from "vue-property-decorator";
import { Icon } from "vant";
import { OrderResult } from "@/api/mine.api";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    Icon,
  },
})
export default class OrderItem extends Vue {
  @Prop() private readonly item!: OrderResult;
  created(){
    console.log(this.item,'dsakjdskaj');
  }
  handleOpenDetail(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/my-order/${this.item.order_no}?time=${String(
          getNow()
        )}`
      );
    }
    this.$router.push({
      name: "MyOrderDetail",
      params: { order_no: this.item.order_no },
      query: { time: String(getNow()) },
    });
  }

  get color(): string {
    const { index } = this.item.status;
    if (index >= 3 || index === 0) return "error";
    if (index === 2) return "complete";
    if (index === 1) return "success";
    return "";
  }
}
